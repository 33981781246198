<template>
  <div>
    <div v-if="Object.keys(hub_stock).length !== 0">
      <b-modal id="hub-stock-update-modal" :title="'Update - '+ hub_stock.name" size="lg" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded">

          <div class="form-row">
            <div class="col-md-4 col-sm-4 col-lg-4">
              <label for="name" class="col-form-label col-form-label-sm">Product Name <span class="custom-required">*</span></label>
              <input type="text" disabled readonly v-model="hub_stock.name" v-validate="'required'" id="name" name="name" class="form-control form-control-sm" placeholder="Enter name"/>
              <div class="invalid-feedback">Product name is required</div>
            </div>
            <div class="col-md-8 col-sm-8 col-lg-8">
              <label for="stock_amount" class="col-form-label col-form-label-sm">Product Stock <span class="custom-required">* Please sum Current stock with getting item amount, before updating</span></label>
              <input type="text" v-model="hub_stock.stock_amount" v-validate="'required|numeric'" id="stock_amount" name="stock_amount" class="form-control form-control-sm" placeholder="Enter product stock"/>
              <div class="invalid-feedback">Product stock is required</div>
            </div>
          </div>

          <div class="form-row mt-3 mb-3">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <a-button type="primary" class="text-center mr-2" :loading="loader"  @click.prevent="update"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Update Item Stock</a-button>
              <a-button type="danger" class="text-center" @click="$bvModal.hide('hub-stock-update-modal')">Cancel</a-button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'

export default {
  name: 'hubStockUpdate',
  props: ['hub_stock', 'show'],
  data() {
    return {
      validation_errors: {},
      loader: false,
    }
  },
  mounted() {},
  methods: {
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          apiClient.post('api/hub/product-stock/update', this.hub_stock)
            .then(response => {
              this.$bvModal.hide('hub-stock-update-modal')
              this.loader = false
              if (!response.data.error) {
                this.$emit('search')
                this.$notification.success({
                  message: response.data.message,
                })
              } else {
                this.$notification.error({
                  message: response.data.message,
                })
              }
            })
            .catch(error => {
              this.loader = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>

</style>
